import { IS_ADV_ELIGIBLE_USER, IS_ADVANTAGE_USER, IS_TRIAL_USER } from '@/data/constants/marketing';
import { Order, OrderResponse } from '../types/Order';

type FilterArray = {
    baseMarketingKey:string,
    baseMarketingValue: string
}
export const isAdvantageUser = (filterFrom: FilterArray[]) => {
    const filteredItemFirstType = filterFrom?.find((item)=>item?.baseMarketingKey===IS_ADV_ELIGIBLE_USER);
    const filteredItemSecondSecondType = filterFrom?.find((item)=>item?.baseMarketingKey===IS_ADVANTAGE_USER);
    return (filteredItemFirstType?.baseMarketingValue || filteredItemSecondSecondType?.baseMarketingValue) || 'N';
};

export const isAdvantageMember = (
  userSegementsData: FilterArray[],
  orderData: OrderResponse | undefined,
  cart: Order
) => {
  const isAdvantageEnrolled = userSegementsData?.find(
    (item) => item?.baseMarketingKey === IS_ADVANTAGE_USER
  );
  const isAdvTrialUser = userSegementsData?.find(
    (item) => item?.baseMarketingKey === IS_TRIAL_USER
  );

  const isAdvProduct = orderData?.orderItemViewBeansForDisplay?.find(
    (item) => item?.isAdvProd
  );
  const advProdDetails = cart?.orderItem?.find(
    (item) => item?.partNumber === isAdvProduct?.partNumber
  );

  // Logic to check if the user is a trial or permanent adv member from customer validation espot or...
  // from purchased items if it contains any adv product item.
  const permanentAdvMember =
    (isAdvantageEnrolled?.baseMarketingValue === 'Y' &&
      isAdvTrialUser?.baseMarketingValue !== 'Y') ||
    !!(advProdDetails && Number(advProdDetails?.unitPrice) > 0);

  const trialAdvMember =
    (isAdvantageEnrolled?.baseMarketingValue === 'Y' &&
      isAdvTrialUser?.baseMarketingValue === 'Y') ||
    !!(advProdDetails && advProdDetails?.unitPrice === '0.00000');
  const isAdvMember = (trialAdvMember || permanentAdvMember) === true;

  return { permanentAdvMember, trialAdvMember, isAdvMember };
};

export const isAdvantageSubscribedMember = (
  userSegementsData: FilterArray[],
) => {
  const isAdvantageEnrolled = userSegementsData?.find(
    (item) => item?.baseMarketingKey === IS_ADVANTAGE_USER
  );
  const isAdvTrialUser = userSegementsData?.find(
    (item) => item?.baseMarketingKey === IS_TRIAL_USER
  );

  // Logic to check if the user is a trial or permanent adv member from customer validation espot or...
  // from purchased items if it contains any adv product item.
  const permanentAdvMember =
    (isAdvantageEnrolled?.baseMarketingValue === 'Y' &&
      isAdvTrialUser?.baseMarketingValue !== 'Y');

  const trialAdvMember =
    (isAdvantageEnrolled?.baseMarketingValue === 'Y' &&
      isAdvTrialUser?.baseMarketingValue === 'Y');
  const isAdvMember = (trialAdvMember || permanentAdvMember) === true;

  return { permanentAdvMember, trialAdvMember, isAdvMember };
};

export const isAdvantageEnrolledUser = (filterFrom: FilterArray[]) => {
	const filteredItemSecondSecondType = filterFrom?.find(
		(item) => item?.baseMarketingKey === IS_ADVANTAGE_USER
	);
	return filteredItemSecondSecondType?.baseMarketingValue || 'N';
};
