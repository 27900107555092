import { useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import { CUSTOMER_SEGMENT_VALIDATION_ESPOT } from '@/data/constants/marketing';
import { processMarketingPropeties } from '@/data/utils/processMarketingProperties';
import { useMemo } from 'react';

export const userSegementsFetcher = () => {
	const GetESpotContent = () => {
		const dataMap = (contents?: any) =>
			contents?.MarketingSpotData?.at(0)?.baseMarketingSpotActivityData?.map(
				processMarketingPropeties
			);
		const { data: advantageMembership } = useESpotDataFromName(CUSTOMER_SEGMENT_VALIDATION_ESPOT);
		const processEspotData = useMemo(() => dataMap(advantageMembership), [advantageMembership]);
		const userSegementsData = processEspotData?.map((item: any) => item?.data?.at(1));
		return userSegementsData;
	};
	const userSegementsData = GetESpotContent();
	return {
		userSegementsData,
	};
};
