import useSWR from 'swr';
import { Cache } from '@/data/types/Cache';
import { DATA_KEY_APP_DYNAMICS_KEY } from '../constants/dataKey';

export const getAppDynamicsData = async (cache: Cache) => {
	if (process.env.APP_DYNAMICS_KEY) {
		cache.set(DATA_KEY_APP_DYNAMICS_KEY, process.env.APP_DYNAMICS_KEY);
	}
};
export const useAppDynamicsData = () => {
	const { data: appKey } = useSWR(DATA_KEY_APP_DYNAMICS_KEY);
	return {
		appKey,
	};
};
