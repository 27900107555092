/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ESpotContainerType } from '@/data/Content/_ESpotDataFromName';
import { CONTENT_FORMAT_WEB, MARKETING_SPOT_DATA_TYPE } from '@/data/constants/marketing';
import { ProcessedContent } from '@/data/types/Marketing';
import { processMarketingContent } from '@/utils/processMarketingContent';
import {
	ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainerBaseMarketingSpotActivityDataContainer,
	ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainerBaseMarketingSpotActivityDataContainerPropertiesContainer,
} from 'integration/generated/transactions/data-contracts';
type dataObj =
	ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainerBaseMarketingSpotActivityDataContainerPropertiesContainer[];

type BaseMarketingSpotData =
	ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainerMarketingSpotDataContainerBaseMarketingSpotActivityDataContainer;
type ExtendedBaseMarketingSpotData = BaseMarketingSpotData & { activityIdentifierId: number };
type ExtendedProcessedContent = ProcessedContent & {
	data?: dataObj;
	activityIdentifierId?: number;
	activityIdentifierName?: string | undefined;
};
export const processMarketingPropeties = (
	content: ExtendedBaseMarketingSpotData
): ExtendedProcessedContent => {
	if (content.baseMarketingSpotDataType === MARKETING_SPOT_DATA_TYPE.DM_NVP) {
		const { activityIdentifierId, activityIdentifierName } = content;
		if (content.activityFormat === CONTENT_FORMAT_WEB && content.properties) {
			return {
				id: content.baseMarketingSpotActivityID,
				data: content.properties,
				contentUrl: content.contentUrl,
				activityIdentifierId,
				activityIdentifierName,
			};
		}
	}
	return {};
	// TODO List of attachments.
};

export const dataMapContent = (contents: ESpotContainerType) =>
	contents?.MarketingSpotData?.at(0)?.baseMarketingSpotActivityData?.map(processMarketingContent);
