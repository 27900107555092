import { useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import { CUSTOMER_SEGMENT_VALIDATION_ESPOT } from '@/data/constants/marketing';
import { processMarketingPropeties } from '@/data/utils/processMarketingProperties';
import { useMemo } from 'react';

export const userAdvMembership = () => {
    const GetESpotContent = () => {
        const dataMap = (contents?: any) => contents?.MarketingSpotData?.at(0)?.baseMarketingSpotActivityData?.map(processMarketingPropeties)?.at(0)?.data;
        const { data: advantageMembership } = useESpotDataFromName(CUSTOMER_SEGMENT_VALIDATION_ESPOT);
        const AdvContentByProductdId = useMemo(() => dataMap(advantageMembership), [advantageMembership]);
        return AdvContentByProductdId;
    };
        const user_membership = GetESpotContent();

    return {
        user_membership,
    };
};
