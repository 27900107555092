import { FC, useEffect } from 'react';
type Props = {
    appKey: string;
};
const AppDynamicsInitializerComponent: FC<Props> = ({ appKey }) => {
    useEffect(() => {
        const adrumConfigScript = document.createElement('script');
        const code = `window['adrum-start-time'] = new Date().getTime();
        ((config) => {
            config.appKey = '${appKey}';
            config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
            config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
            config.beaconUrlHttp = 'http://pdx-col.eum-appdynamics.com';
            config.beaconUrlHttps = 'https://pdx-col.eum-appdynamics.com';
            config.useHTTPSAlways = true;
            config.resTiming = { bufSize: 200, clearResTimingOnBeaconSend: true };
            config.maxUrlLength = 512;
            config.spa = {
                spa2: true,
            };
        })(window['adrum-config'] || (window['adrum-config'] = {}));`;
        adrumConfigScript.appendChild(document.createTextNode(code));
        document.head.appendChild(adrumConfigScript);
        const adrumScript = document.createElement('script');
        adrumScript.src = '/adrum-latest.js'; // Replace with actual path
        document.head.appendChild(adrumScript);
    }, [appKey]);

    return null;
};

export default AppDynamicsInitializerComponent;
