/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { userAdvMembership } from '@/data/Content/_UserAdvantageMembership';
import { FC, createContext, PropsWithChildren } from 'react';


export const AdvantageUserMembershipContext = createContext({});

export const AdvantageUserMembershipProvider: FC<PropsWithChildren> = ({ children }) => {
	const { user_membership } = userAdvMembership();
	return (
		<AdvantageUserMembershipContext.Provider value={{ user_membership }}>
			{children}
		</AdvantageUserMembershipContext.Provider>
	);
};
